import { unstable_cache } from 'next/cache';

import { appContext } from '@services/app-process';
import { getAppConfig } from '@services/config';

import { BytelAxiosRequestConfig, Callback } from '@app-types/axios';
import { UserType } from '@app-types/user';

import { API_X_SOURCE, API_X_VERSION } from '@constants/api';

import { AxiosInstance, AxiosResponse } from 'axios';
import { createHash } from 'crypto';
import { v4 as uuid } from 'uuid';

import { HttpConfig, HttpService } from './http';

type ExtendedRequestConfig = Omit<BytelAxiosRequestConfig, 'metadata'>;

const appConfig = getAppConfig();

export class AxiosService extends HttpService {
    private readonly isApi: boolean;
    private readonly isCms: boolean;
    private httpInstance: AxiosInstance;
    private headers: Record<string, string> = {};

    constructor(axiosInstance: AxiosInstance, isApi: boolean = false, isCms: boolean = false) {
        super();
        this.httpInstance = axiosInstance;
        this.isApi = isApi;
        this.isCms = isCms;
    }

    public setInitialHeaders(headers: Headers): void {
        this.headers = Object.fromEntries(headers.entries());
    }

    public async get<T>(url: string, data: object = {}, httpConfig: HttpConfig = {}): Promise<T> {
        const config = this.initConfig(httpConfig);
        const cacheKey = this.generateCacheKey(url, data, httpConfig.user);

        config.params = data;

        return await this.launchRequest(
            async () => {
                return this.httpInstance.get(url, config).then((response) => this.processResponse<T>(response));
            },
            cacheKey,
            config,
        )();
    }

    public async post<T>(url: string, data?: object, httpConfig: HttpConfig = {}): Promise<T> {
        const config = this.initConfig(httpConfig);
        const cacheKey = this.generateCacheKey(url, data, httpConfig.user);

        return await this.launchRequest(
            async () => {
                return this.httpInstance
                    .post<T>(url, data, config)
                    .then((response) => this.processResponse<T>(response));
            },
            cacheKey,
            config,
        )();
    }

    private launchRequest<T extends Callback>(cb: T, key: string, config: ExtendedRequestConfig): T {
        if (config.next?.revalidate === 0 || typeof window !== 'undefined') {
            return cb;
        }

        return unstable_cache(cb, [key], { tags: config?.next?.tags, revalidate: config?.next?.revalidate });
    }

    protected processResponse<T>(response: AxiosResponse): T {
        return response.data;
    }

    protected initConfig(httpConfig: HttpConfig = {}): ExtendedRequestConfig {
        let headersObject = httpConfig.headers ?? {};

        const config: ExtendedRequestConfig = {
            signal: httpConfig?.controller ? httpConfig.controller.signal : undefined,
        };

        if (httpConfig.user) {
            config.next = {
                ...config.next,
                revalidate: 5 * 60,
            };
            config.user = {
                idPersonne: httpConfig.user.sub,
                login: httpConfig.user.login,
                userType: httpConfig.user.user_type,
            };
        } else if (httpConfig.ttl !== undefined) {
            config.next = {
                ...config.next,
                revalidate: (httpConfig.ttl ?? 60) * 60,
            };
        }

        if (httpConfig?.tags && httpConfig.tags.length > 0) {
            config.next = {
                ...config.next,
                tags: httpConfig.tags,
            };
        }

        if (!this.isCms) {
            headersObject = {
                ...headersObject,
                'content-type': 'application/json',
            };
        } else {
            config.next = {
                ...config.next,
                mute: true,
            };
        }

        headersObject = this.setHeadersApi(httpConfig, headersObject);
        headersObject = this.cleanHeaders(headersObject);

        return { ...config, headers: headersObject };
    }

    private setHeadersApi(httpConfig: HttpConfig, baseHeaders: Record<string, string>): Record<string, string> {
        const headers = { ...baseHeaders };

        headers['x-source'] = API_X_SOURCE;
        if (!headers['x-process']) {
            headers['x-process'] = appContext.getProcess();
        }
        headers['x-request-id'] = uuid();

        if (this.isApi) {
            if (!headers['x-version']) {
                headers['x-version'] = API_X_VERSION.V4;
            }

            headers['accept-encoding'] = 'gzip';

            const clientIp = this.headers['x-forwarded-for'];
            const trackerId = this.headers['x-tracker-id'];
            if (trackerId) {
                headers['x-tracker-id'] = trackerId;
            }

            if (clientIp) {
                headers['x-real-ip'] = clientIp;
                headers['x-bytel-ip'] = clientIp;
            }

            if (appConfig.bench && !headers['x-banc']) {
                headers['x-banc'] = appConfig.bench;
            }

            if (httpConfig.user) {
                headers['authorization'] = `Bearer ${httpConfig.user.accessToken}`;
            }
        }

        return headers;
    }

    private cleanHeaders(headers: Record<string, string>): Record<string, string> {
        delete headers['host'];
        delete headers['accept'];
        delete headers['accept-language'];
        delete headers['connection'];
        delete headers['content-length'];
        delete headers['origin'];
        return headers;
    }

    private generateCacheKey(url: string, data?: object, user?: UserType): string {
        const dataString = data ? JSON.stringify(data) : '';
        const userString = user ? JSON.stringify(user.login) : '';
        const rawKey = `${url}-${dataString}-${userString}`;
        return createHash('sha256').update(rawKey).digest('hex');
    }
}
