import { Session } from 'next-auth';

import { auth } from '@constants/auth';

import { isPast, parseISO } from 'date-fns';

export async function getServerSession() {
    return auth();
}

export const isSessionExpired = (session?: Session | null) => {
    return (
        session?.user?.exp &&
        session?.expires &&
        isPast(Math.min(session.user.exp * 1000, parseISO(session.expires).getTime()))
    );
};
