import { UserType } from '@app-types/user';

export enum CACHE_TAGS {
    'ALL' = 'cacheAll',
    'PRODUCTS' = 'cacheProducts',
    'PRODUCTS_STOCK' = 'cacheProductsStock',
    'CMS' = 'cacheCms',
    'PRODUCT_DETAIL' = 'cacheProductDetail',
    'NUMERIQUES' = 'cacheNumeriques',
    'FAI' = 'cacheFai',
}

export type HttpConfig = {
    headers?: Record<string, string>;
    controller?: AbortController;
    tags?: CACHE_TAGS[];
    ttl?: number;
    user?: UserType;
};

export abstract class HttpService {
    public abstract get<T>(url: string, data?: Record<string, string>, config?: HttpConfig): Promise<T>;

    public abstract post<T>(url: string, data?: object, config?: HttpConfig): Promise<T>;

    protected abstract processResponse<T>(response: unknown): Promise<T>;
}
