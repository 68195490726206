import NextAuth from 'next-auth';

import { getAppConfig } from '@services/config';
import { httpService } from '@services/http';

import { Oauth2UserType } from '@app-types/oauth2';

import { JWT } from '@auth/core/jwt';

const appConfig = getAppConfig();

type TokenRequestContextType = {
    params: { code: string };
    checks: { code_verifier: string };
    client: { client_id: string };
    provider: { token: { url: string }; callbackUrl: string };
};

// process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export const {
    handlers: { GET, POST },
    auth,
    signIn,
} = NextAuth({
    secret: process.env.NEXTAUTH_SECRET,
    trustHost: true,
    cookies: {
        sessionToken: {
            name: `next-session-token`,
            options: {
                httpOnly: true,
                sameSite: 'lax',
                path: '/',
                secure: process.env.NODE_ENV === 'production',
            },
        },
        callbackUrl: {
            name: `next-callback-url`,
            options: {
                sameSite: 'lax',
                path: '/',
                secure: process.env.NODE_ENV === 'production',
            },
        },
        csrfToken: {
            name: `next-csrf-token`,
            options: {
                httpOnly: true,
                sameSite: 'lax',
                path: '/',
                secure: process.env.NODE_ENV === 'production',
            },
        },
        pkceCodeVerifier: {
            name: `next-pkce-code-verifier`,
            options: {
                httpOnly: true,
                sameSite: 'lax',
                path: '/',
                secure: process.env.NODE_ENV === 'production',
                maxAge: 900,
            },
        },
        state: {
            name: `next-auth-state`,
            options: {
                httpOnly: true,
                sameSite: 'lax',
                path: '/',
                secure: process.env.NODE_ENV === 'production',
                maxAge: 900,
            },
        },
        nonce: {
            name: `next-auth-nonce`,
            options: {
                httpOnly: true,
                sameSite: 'lax',
                path: '/',
                secure: process.env.NODE_ENV === 'production',
            },
        },
    },
    providers: [
        {
            id: 'oauth2',
            name: 'Oauth2',
            issuer: appConfig.oauth2.url,
            type: 'oidc',
            checks: ['pkce', 'state'],
            clientId: appConfig.oauth2.clientId,
            authorization: {
                url: appConfig.oauth2.authorizationUrl,
                params: { scope: appConfig.oauth2.scope },
            },
            token: {
                url: appConfig.oauth2.tokenUrl,
                async request(context: TokenRequestContextType) {
                    const urlParams = {
                        grant_type: 'authorization_code',
                        code_verifier: context.checks.code_verifier,
                        code: context.params.code,
                        client_id: context.client.client_id,
                        redirect_uri: context.provider.callbackUrl,
                    };

                    return httpService
                        .post(context.provider.token.url, urlParams, {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                        })
                        .then((res) => ({ tokens: res }))
                        .catch(() => {
                            throw new Error('Fetch failed');
                        });
                },
            },
            userinfo: appConfig.oauth2.userInfoUrl,
            client: {
                token_endpoint_auth_method: 'none',
            },
            profile(profile: Oauth2UserType) {
                return { id: profile.sub, ...profile };
            },
        },
    ],
    session: {
        maxAge: 60 * 60,
    },
    callbacks: {
        async session({ session: { expires }, token }) {
            return {
                user: token,
                expires,
            };
        },
        async jwt({ token, profile, account }) {
            let newToken: JWT = token;

            if (profile) {
                newToken = profile as JWT;
            }

            if (account) {
                newToken = { ...newToken, accessToken: account?.access_token };
            }

            return newToken;
        },
    },
});
